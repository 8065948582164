import getenvvar from "utils/Env";

export const GOOGLE_ANALYTICS_ID = getenvvar("REACT_APP_GOOGLE_ANALYTICS");
export const HOTJAR_SCRIPT_ID = "hotjar-script";

/**
 * @typedef {Object} CookieState
 * @property {string} UNKNOWN - Represents the state where the cookie consent is unknown.
 * @property {string} NECESSARY_ONLY - Represents the state where only necessary cookies are accepted.
 * @property {string} ACCEPTED_ALL - Represents the state where all cookies are accepted.
 */
export const CookieState = {
  UNKNOWN: "unknown",
  NECESSARY_ONLY: "necessary_only",
  ACCEPTED_ALL: "accepted_all"
};

export const COOKIE_CONSENT_KEY = "CookieConsent";
export const COOKIE_CONSENT_URL = "https://ec.europa.eu/info/cookies_en";
