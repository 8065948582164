import React from "react";
import PropTypes from "prop-types";

// Material UI
import Button from "@material-ui/core/Button";

// i18n
import { useTranslation } from "react-i18next";

// Assets
import cookieConsentIcon from "./cookie_consent_icon.svg";

/**
 * CookieSmallBanner component displays a small banner for cookie consent.
 *
 * @param {Object} props - The component props.
 * @param {string} props.url - The URL for more information about cookies.
 * @param {Function} props.onAccept - The callback function to handle the accept action.
 * @param {Function} props.onDecline - The callback function to handle the decline action.
 * @returns {React.ReactElement} The rendered component.
 */
export function CookieSmallBanner({ url, onAccept, onDecline }) {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: "100%",
        background: "rgba(61, 65, 66, 0.85)",
        zIndex: 1000000,
        padding: "25px 17% 25px 17%",
        bottom: 0,
        position: "fixed",
        display: "flex",
        alignItems: "center"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div
          style={{
            marginRight: "1rem"
          }}
        >
          <img
            src={cookieConsentIcon}
            alt="cookie-img"
            style={{
              margin: 0,
              opacity: 0.8,
              transform: "scaleX(-1)"
            }}
          />
        </div>
        <div>
          <div
            style={{
              marginBottom: "4px",
              fontWeight: 700,
              fontSize: 17,
              color: "#00BEF3"
            }}
          >
            {t("cookies.CookieConsentTitle")}
          </div>
          <div
            style={{
              fontSize: 14,
              color: "#ffffff"
            }}
          >
            {t("cookies.CookieConsentDescription")}
            <a
              href={url}
              rel="noopener noreferrer"
              target="_blank"
              style={{
                color: "#05a7e7",
                fontWeight: 700,
                textDecoration: "underline",
                marginLeft: 5
              }}
            >
              {t("cookies.CookiesConsentReadMore")}
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "8px",
          marginLeft: "auto"
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{
            margin: "2px",
            padding: "5px 25px"
          }}
          onClick={onAccept}
        >
          {t("cookies.CookiesConsentAccept")}
        </Button>
        <Button
          variant="contained"
          style={{
            margin: "2px",
            padding: "5px 25px"
          }}
          onClick={onDecline}
        >
          {t("cookies.CookiesConsentDecline")}
        </Button>
      </div>
    </div>
  );
}

CookieSmallBanner.propTypes = {
  url: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired
};
