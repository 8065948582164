import React, { useEffect, useState } from "react";

// Custom components
import { useCookieConsent } from "./CookieConsentContext";

// Assets
import { CookieState, COOKIE_CONSENT_URL } from "./constants";

// Components
import { CookieSmallBanner } from "./CookieSmallBanner";

/**
 * CookieConsentBanner component displays a small banner for cookie consent.
 * The banner is shown based on the user's cookie consent choice.
 *
 * @returns {React.ReactNode|null} The CookieConsentBanner component or null if not visible.
 */
function CookieConsentBanner() {
  const [visible, setVisible] = useState(false);

  const { cookieConsentChoice, acceptCookieConsent, declineCookieConsent } =
    useCookieConsent();

  useEffect(() => {
    setVisible(cookieConsentChoice === CookieState.UNKNOWN);
  }, [cookieConsentChoice]);

  if (!visible) return null;

  return (
    <CookieSmallBanner
      url={COOKIE_CONSENT_URL}
      onAccept={acceptCookieConsent}
      onDecline={declineCookieConsent}
    />
  );
}

export default CookieConsentBanner;
