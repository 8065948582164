import React, { useEffect, useContext, useState, createContext } from "react";

import PropTypes from "prop-types";

import { CookieState } from "./constants";
import {
  addAnalyticsScripts,
  getCookieConsent,
  removeAnalyticsScripts,
  setCookieConsent
} from "./helpers";

const CookieConsentContext = createContext({});

export function CookieConsentProvider({ children }) {
  const [cookieConsentChoice, setCookieConsentChoice] = useState(
    CookieState.UNKNOWN
  );

  // Side-effect for loading the cookie consent state from the local storage
  useEffect(() => setCookieConsentChoice(getCookieConsent()), []);

  // Side-effect handling the logic for adding and removing the analytics scripts
  useEffect(() => {
    if (
      cookieConsentChoice === CookieState.NECESSARY_ONLY ||
      cookieConsentChoice === CookieState.UNKNOWN
    ) {
      removeAnalyticsScripts();
      return;
    }

    addAnalyticsScripts();
  }, [cookieConsentChoice]);

  function updateCookieConsent(consent) {
    // Save it in the local storage
    setCookieConsent(consent);
    // And in the state
    setCookieConsentChoice(consent);
  }

  return (
    <CookieConsentContext.Provider
      value={{
        cookieConsentChoice,
        acceptCookieConsent: () =>
          updateCookieConsent(CookieState.ACCEPTED_ALL),
        declineCookieConsent: () =>
          updateCookieConsent(CookieState.NECESSARY_ONLY)
      }}
    >
      {children}
    </CookieConsentContext.Provider>
  );
}

CookieConsentProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

export function useCookieConsent() {
  return useContext(CookieConsentContext);
}
